<svelte:options tag="verum-step-category" />

<script>
    import { type, categories } from "../store/store.js";

    export let options = $categories.reduce((acc, item) => {
        if(item.type === $type) {
            acc.push(item)
        } else if($type === 'other') {
            acc.push(item)
        }
        return acc
    }, [])

    const slugify = (str = "") =>
        str.toLowerCase().replace(/ /g, "-").replace(/\./g, "");
</script>

<div class="container">
    <p>What category does fall under? Select any relevant categories.</p>
    <div>
        {#each options as { id, name, selected }}
        <div class="c-checkable-input">
            <input class="c-checkbox js-checkbox" id={slugify(name)} type="checkbox" bind:checked={selected}
                value={id} on:click={categories.click} />
            <label for={slugify(name)}> {name}</label>
        </div>
        {/each}
    </div>
</div>

<style>
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 375px;
        max-height: 350px;
        margin-bottom: 40px;
        overflow-y: auto !important;
        /* overflow-y: scroll; */
    }

    p {
        color: #5F6367;
    }

    .c-checkable-input {
        display: inline-block;
    }

    .c-checkable-input input {
        left: -1000vw;
        position: fixed;
        top: -1000vh;
        visibility: hidden;
    }

    .c-checkable-input label {
        background: transparent no-repeat 0.5em center/1.25em;
        cursor: pointer;
        display: block;
        padding: 0.5rem 0.5rem 0.5rem 3rem;
        display: inline-block;
        margin: 6px;
        border: 1px solid #DFE2E5;
        border-radius: 4px;
    }

    .c-checkable-input label:hover {
        color: #1670c9;
        background-color: rgba(0, 0, 0, 0.1);
    }

    .c-checkable-input .c-checkbox:checked~label {
        background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' stroke-width='4' stroke='dodgerblue' fill='dodgerblue'%0A%3E%3Cpath d='M13 2 h74 a11 11,0 0 1,11 11 v74 a11 11,0 0 1,-11 11 h-74 a11 11,0 0 1,-11-11 v-74 a11 11,0 0 1,11-11 z'/%3E%3Cpath fill='white' stroke='white' d='M20 54 l20 20,40-40,-2-2,-38 38,-18-18 z' /%3E%3C/svg%3E%0A");
        border: 1px solid #1670c9;
        color: #1670c9;
    }

    .c-checkable-input .c-checkbox~label {
        background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' stroke-width='4' stroke='black' fill='white'%0A%3E%3Cpath d='M13 2 h74 a11 11,0 0 1,11 11 v74 a11 11,0 0 1,-11 11 h-74 a11 11,0 0 1,-11-11 v-74 a11 11,0 0 1,11-11 z' /%3E%3C/svg%3E");
    }

    label {
        white-space: nowrap;
    }

    @media (max-width: 500px) {
        .container {
            width: 300px;
        }
    }
</style>