<svelte:options tag="verum-summary" />

<script>
    import { type, categories, member, agreementCheckbox, fileUploads } from '../store/store.js'

    // const uploads = $fileUploads
    const category = $categories
        .reduce((acc, item) => {
            if (item.selected) {
                acc.push(item.name)
            }
            return acc
        }, [])
        .join(', ')
    const badge = $member.badge ? `Badge #${$member.badge}` : ''
    const citation = $member.citation ? `Citation #${$member.citation}` : ''
    const officer = [$member.name, badge, citation, $member.description]
        .filter(val => val !== '')
        .join(', ')
        .replace(/^,/, '')
    const comments = $member.comments ? $member.comments : ''


    // let checked = false

    function formatTime() {
        const time = new Date($member.time)
        return time.toLocaleString('en-US', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        })
    }
</script>

<div class="container">
    <div class="border">
        <dl class="grid">
            <dt>TYPE</dt>
            <dd class="capitalize">{$type}</dd>
            <dt>CATEGORY</dt>
            <dd>{category}</dd>
            <dt>LOCATION</dt>
            <dd>{$member.location}</dd>
            <dt>DAY / TIME</dt>
            <dd>{formatTime()}</dd>
            <dt>OFFICER</dt>
            <dd>{officer}</dd>
            <dt>COMMENTS</dt>
            <dd>{comments}</dd>
        </dl>

        <!-- {#each uploads as upload, i}
    <div class="images">
        <img width="50" height="50" src={upload.img} alt="video or image upload" />
        {upload.name}
    </div>
    {/each} -->
        <p>
            By providing information on this platform, the user attests that all
            information submitted is accurate and truthful to the best of their
            knowledge. Any individual found to have deliberately provided false
            or misleading information may be subject to fines and/or legal
            prosecution, which may result in imprisonment for a determined
            period, in accordance with applicable laws and regulations. Users
            are encouraged to review their submissions and ensure the integrity
            of all information provided. Use of this platform signifies the
            user's understanding and acceptance of these terms.
        </p>
        <div class="c-checkable-input">
        <!-- <input class="c-checkbox js-checkbox" id="terms-and-agreement" type="checkbox" bind:checked={agreementChecked}
                value={agreementChecked} on:click={(e) => agreementCheckbox.click(e?.target?.value === 'true')} /> -->
        <input class="c-checkbox js-checkbox" id="terms-and-agreement" type="checkbox"
                on:click={(e) => {
                    // console.log(e?.target?.value)
                    agreementCheckbox.click(!$agreementCheckbox)
                }} />
                <label for="terms-and-agreement">I have read and agree to all terms stated above.</label>
        </div>
    </div>
</div>

<style>
    .container {
        max-height: 350px;
    }

    .border {
        background-color: #fff;
        max-width: 352px;
        max-height: 400px;
    }

    .grid {
        display: grid;
        grid-template-columns: repeat(2, minmax(auto, auto));
        grid-row-gap: 10px;
    }

    .grid > * {
        /* background-color: #F8F8F9; */
        padding: 6px;
    }

    dl > * {
        align-self: center;
        font-size: 12px;
        min-height: 22px;
    }

    dt {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        border-top: 1px solid #dfe2e5;
        border-bottom: 1px solid #dfe2e5;
        border-left: 1px solid #dfe2e5;
        color: #5f6367;
        font-weight: 600;
        min-width: 160px;
    }

    dd {
        margin-inline-start: 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border-top: 1px solid #dfe2e5;
        border-bottom: 1px solid #dfe2e5;
        border-right: 1px solid #dfe2e5;
        color: #848a8e;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .capitalize {
        text-transform: capitalize;
    }

    .text {
        color: #5f6367;
        font-weight: 300;
    }

    button {
        border: none;
        background-color: #fff;
        color: #ffffff;
        padding: 5px 10px;
        cursor: pointer;
    }

    button:hover > * {
        fill: #b0b8bd;
    }

    .images {
        margin-bottom: 6px;
        align-items: center;
        display: flex;
    }

    img {
        border-radius: 5px;
        margin-right: 6px;
    }

    p {
        color: #5f6367;
        text-align: justify;
    }

    label {
        color: #5f6367;
    }

    .c-checkable-input {
        display: inline-block;
        padding-bottom: 50px;
    }

    .c-checkable-input input {
        left: -1000vw;
        position: fixed;
        top: -1000vh;
        visibility: hidden;
    }

    .c-checkable-input label {
        background: transparent no-repeat 0.5em center/1.25em;
        cursor: pointer;
        display: block;
        padding: 0.5rem 0.5rem 0.5rem 3rem;
        display: inline-block;
        margin: 6px;
    }

    .c-checkable-input label:hover {
        color: #1670c9;
        background-color: rgba(0, 0, 0, 0.1);
    }

    .c-checkable-input .c-checkbox:checked~label {
        background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' stroke-width='4' stroke='dodgerblue' fill='dodgerblue'%0A%3E%3Cpath d='M13 2 h74 a11 11,0 0 1,11 11 v74 a11 11,0 0 1,-11 11 h-74 a11 11,0 0 1,-11-11 v-74 a11 11,0 0 1,11-11 z'/%3E%3Cpath fill='white' stroke='white' d='M20 54 l20 20,40-40,-2-2,-38 38,-18-18 z' /%3E%3C/svg%3E%0A");
    }

    .c-checkable-input .c-checkbox~label {
        background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' stroke-width='4' stroke='black' fill='white'%0A%3E%3Cpath d='M13 2 h74 a11 11,0 0 1,11 11 v74 a11 11,0 0 1,-11 11 h-74 a11 11,0 0 1,-11-11 v-74 a11 11,0 0 1,11-11 z' /%3E%3C/svg%3E");
    }

    @media (max-width: 500px) {
        .container {
            width: 300px;
        }
    }
</style>
