import { writable, derived } from 'svelte/store'
import { time } from '../util/util'

const _base = {
    agency: null,
    time: time(),
    badge: '',
    name: '',
    description: '',
    referenceType: 'citation',
    citation: '',
    comments: '',
    location: '',
    lat: null,
    lng: null,
}

/** Store for your data. 
This assumes the data you're pulling back will be an array.
If it's going to be an object, default this to an empty object.
**/
export const apiData = writable({})

/** Data transformation.
For our use case, we only care about the drink names, not the other information.
Here, we'll create a derived store to hold the drink names.
**/
export const agencyData = derived(apiData, $apiData => {
    if ($apiData) {
        return $apiData
    }
    return {}
})

// export const type = writable('')
// export const typeData = derived(type, $type => {
//     console.log($type)
//     return $type
// })

function writeStep() {
    const { subscribe, set, update } = writable(0)

    return {
        subscribe,
        click: v => update(n => v),
        reset: () => set(0),
    }
}

function writeType() {
    const { subscribe, set, update } = writable('praise')

    return {
        subscribe,
        click: v => update(n => v),
        reset: () => set(''),
    }
}

function writeCategory() {
    const { subscribe, set, update } = writable([
        {
            id: 1,
            name: 'Sexual Harrassment',
            selected: false,
        },
        {
            id: 2,
            name: 'Unprofessional',
            selected: false,
        },
        {
            id: 3,
            name: 'Excessive Force',
            selected: false,
        },
        {
            id: 4,
            name: 'Rude',
            selected: false,
        },
    ])

    return {
        subscribe,
        set: o => set(o),
        click: v =>
            update(n => {
                return n.map(item => {
                    if (item.value === Number(v.target.value)) {
                        item.selected = !item.selected
                    }
                    return item
                })
            }),
        reset: () => set([]),
    }
}

function writeMember(o = {}) {
    const { subscribe, set, update } = writable({ ...o })

    return {
        subscribe,
        set: o => set(o),
        update,
        reset: () => set(_base),
    }
}

function writeFileUploads(o = []) {
    const { subscribe, set, update } = writable([...o])

    return {
        subscribe,
        set: o => set(o),
        update,
        reset: () => set([]),
    }
}

function writeAgreementCheckbox() {
    const { subscribe, set, update } = writable(false)

    return {
        subscribe,
        click: v => update(n => v),
        reset: () => set(false),
    }
}

export const step = writeStep()
export const type = writeType()
export const categories = writeCategory()
export const member = writeMember(_base)
export const agreementCheckbox = writeAgreementCheckbox()
export const fileUploads = writeFileUploads()
