<svelte:options tag="verum-step-time" />

<script>
    import { onMount } from 'svelte'
    import { isFile } from 'is-what'
    import { member } from '../store/store.js'
    import { time } from '../util/util'

    onMount(() =>
        member.set({
            ...$member,
            time: time(),
        })
    )

    // let avatar,
    //     fileinput,
    //     fileList = [],
    //     imageList = []
    // const onFileSelected = e => {
    //     console.log(e.target.files[0])
    //     let image = e.target.files[0]
    //     fileList.push(image)
    //     let reader = new FileReader()
    //     reader.readAsDataURL(image)
    //     reader.onload = e => {
    //         avatar = e.target.result
    //     }
    // }

    // TODO: TESTING VERSION JAVASCRIPT
    // let input
    // let container
    // let image
    // let placeholder
    // let showImage = false
    // let files

    // function onChange() {
    //     files = input.files
    //     const file = input.files[0]

    //     if (file) {
    //         showImage = true

    //         const reader = new FileReader()
    //         reader.addEventListener('load', () => {
    //             image.setAttribute('src', reader.result)
    //         })
    //         reader.readAsDataURL(file)

    //         return
    //     }
    //     showImage = false
    // }

    // const fileData = []
    // $: if (files) {
    //     const formData = new FormData()

    //     for (const name in files) {
    //         formData.append(name, files[name])
    //         if (isFile(files[name])) {
    //             fileData.push({
    //                 type: files[name]?.type,
    //                 filename: files[name]?.name,
    //                 upload: formData,
    //                 voiceId: Math.floor(Math.random() * 90000) + 10000,
    //             })
    //             console.log(fileData)
    //         }
    //     }
    // }
</script>

<div class="container">
    <p>Enter the day and time of the incident.</p>
    <input
        type="datetime-local"
        placeholder="Date / Time"
        name="time"
        bind:value={$member.time}
    />
    <br />
    <!-- <button
        class="upload"
        on:click={() => {
            input.click()
        }}>Upload File(s)</button
    >
    <input
        style="display:none"
        type="file"
        accept="*"
        on:change={onChange}
        bind:this={input}
        multiple
    /> -->

        <!-- **** TESTING VERSION HTML ****
    <p>Upload images or videos.</p>
    <input
        bind:this={input}
        on:change={onChange}
        type="file"
        accept="*"
        multiple
    /> -->


    <!-- <div bind:this={container}>
        {#if showImage}
            <img bind:this={image} width="100px" height="100px" src="" alt="Preview" />
        {:else}
            <span bind:this={placeholder}>Image Preview</span>
        {/if}
    </div> -->

    <!-- <button
        class="upload"
        on:click={() => {
            fileinput.click()
        }}>Upload File(s)</button
    > -->
    <!-- <input
        style="display:none"
        type="file"
        accept="*"
        on:change={e => onFileSelected(e)}
        bind:this={fileinput}
    /> -->
    <!-- {#if avatar}
        <img class="avatar" height="200px" src={avatar} alt="d" />
    {:else}
        <img
            class="avatar"
            height="200px"
            src="https://cdn4.iconfinder.com/data/icons/small-n-flat/24/user-alt-512.png"
            alt=""
        />
    {/if} -->
</div>

<style>
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 352px;
        max-height: 350px;
        margin-bottom: 40px;
    }

    p {
        color: #5f6367;
    }

    input {
        padding: 10px 20px 10px 20px;
        border: 1px solid #dfe2e5;
        width: 100%;
        border-radius: 4px;
    }

    input[type='datetime-local' i] {
        font-family: 'Poppins', sans-serif;
    }

    .upload {
        background-color: #dfe2e5;
        border-radius: 12px;
    }
</style>
