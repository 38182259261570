<svelte:options tag="verum-step-member" />

<script>
    import { member } from "../store/store.js";

    // let options = [{
    //     value: 'ticket',
    //     label: 'Ticket'
    // }, {
    //     value: 'violation',
    //     label: 'Violation'
    // },
    // {
    //     value: 'citation',
    //     label: 'Citation'
    // }
    // ];

    const slugify = (str = "") =>
        str.toLowerCase().replace(/ /g, "-").replace(/\./g, "");

    // function handleClick(e) {
    //     $member.referenceType = e.target.value
    // }
</script>
<div class="container">
    <p>Enter the badge number, name or any information about the officer(s) involved.</p>
    <input placeholder="Enter Badge #" type="text" name="badge" bind:value={$member.badge} />
    <input placeholder="Enter Name" type="text" name="name" bind:value={$member.name} />
    <input placeholder="Input any descriptions" type="text" name="description" bind:value={$member.description} />
    <!-- {#each options as { value, label }}
    <input class="sr-only" type="radio" id={slugify(label)} on:click={e=> handleClick(e)} name={value} value={value}
    checked={$member.referenceType === value} />
    <label class={$member.referenceType===value ? 'blue' : '' } for={slugify(label)}>
        {label}
    </label>
    {/each} -->
    <input placeholder="Citation / Violation number" type="text" name="citation" bind:value={$member.citation} />
</div>

<style>
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 352px;
        max-height: 350px;
        margin-bottom: 40px;
        width: 352px;
    }

    p {
        color: #5F6367;
    }

    .container>input {
        margin: 10px 0 10px 0;
    }

    input {
        padding: 10px 0 10px 10px;
        margin-left: 10px;
        margin-right: 10px;
        border: 1px solid #DFE2E5;
        width: 100%;
        border-radius: 4px;
    }

    input[type="text" i] {
        font-family: 'Poppins', sans-serif;
        ;
    }

    .blue {
        color: #1670C9;
    }

    .sr-only {
        display: none;
    }

    input[type="radio"]+label {
        cursor: pointer;
        position: relative;
        padding-left: 30px;
        line-height: 20px;
        right: 100px;
    }

    input[type="radio"]+label::before {
        content: "";
        display: inline-block;
        width: 20px;
        aspect-ratio: 1;
        border: 1px solid black;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        opacity: 1;
        transition: all 0.3s;
    }

    input[type="radio"]+label::after {
        content: "";
        display: inline-block;
        width: 10px;
        aspect-ratio: 1;
        border: 1px solid black;
        background: black;
        border-radius: 50%;
        position: absolute;
        left: 5px;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0;
        transition: all 0.3s;
    }

    input[type="radio"]:checked+label::after {
        opacity: 1;
    }

    label {
        display: flex;
        flex-direction: column;
        width: 220px;
        margin-bottom: 6px;
    }

    @media (max-width: 500px) {
        .container {
            width: 300px;
        }
        input[type="radio"]+label {
            right: 50px;
        }
    }
</style>