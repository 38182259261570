import { writable } from 'svelte/store'

function writeReady(o = false) {
    const { subscribe, set } = writable(o)

    return {
        subscribe,
        set: o => set(o)
    }
}

function writeBlur(o = false) {
    const { subscribe, set } = writable(o)

    return {
        subscribe,
        set: o => set(o)
    }
}

function writeClear(o = false) {
    const { subscribe, set } = writable(o)

    return {
        subscribe,
        set: o => set(o)
    }
}

function writePlaceChanged(o = {}) {
    const { subscribe, set, update } = writable({ ...o })

    return {
        subscribe,
        set: o => set(o),
        update,
        reset: () => set({}),
    }
}

export const mapsLoaded = writable(false)
export const mapsLoading = writable(false)
export const ready = writeReady()
export const blur = writeBlur()
export const clear = writeClear()
export const placeChanged = writePlaceChanged()