<svelte:options tag="verum-step-location" />

<!-- <script>
    import { onMount } from 'svelte'
    import { member } from '../store/store.js'

    $: input = document.getElementById('autocomplete')
    // let input
    let container
    let marker
    let autocomplete
    let geocoder
    let map
    let zoom = 8
    const controls = {
        mapTypeControl: false,
        streetViewControl: false,
        zoomControl: false,
        scaleControl: false,
        overviewMapControl: false,
        rotateControl: false,
        panControl: false,
        fullscreenControl: false,
    }
    export let center = {}

    async function onInit() {
        const latLong =
            $member.lat !== null && $member.lng !== null
                ? { lat: $member.lat, lng: $member.lng }
                : { ...center }
        map = await new google.maps.Map(container, {
            zoom,
            center: latLong,
            ...controls,
        })
        console.log(input)
        autocomplete = new google.maps.places.Autocomplete(input, {
            types: ['geocode'],
            // fields: ['place_id', 'geometry', 'name', 'formatted_address'],
        })
        autocomplete.addListener('place_changed', () => {
            console.log(autocomplete)
            const place = autocomplete.getPlace()

            if (place) {
                center = {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                }

                $member.location = place.formatted_address
                $member.lat = center.lat
                $member.lng = center.lng
                onInit()
            }
        })

        marker = await new google.maps.Marker({
            position: latLong,
            draggable: true,
            animation: google.maps.Animation.DROP,
            map,
            title: 'Location',
        })

        await google.maps.event.addListener(marker, 'dragend', function () {
            geocodePosition(marker.getPosition())
        })
    }

    onMount(() => {
        // onInit()
    })

    function geocodePosition(pos) {
        geocoder = new google.maps.Geocoder()
        geocoder.geocode(
            {
                latLng: pos,
            },
            (results, status) => {
                if (status == google.maps.GeocoderStatus.OK) {
                    $member.location = results[0].formatted_address
                    $member.lat = results[0].geometry.location.lat()
                    $member.lng = results[0].geometry.location.lng()
                } else {
                    console.error(
                        'Cannot determine address at this location.' + status
                    )
                }
            }
        )
    }

    function fillInAddress() {
        // TODO: https://jsfiddle.net/solodev/ykajnmkk
        // Get the place details from the autocomplete object.
        console.log(autocomplete)
        const place = autocomplete.getPlace()

        if (place) {
            center = {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
            }

            $member.location = place.formatted_address
            $member.lat = center.lat
            $member.lng = center.lng
            onInit()
        }
        // // Get each component of the address from the place details
        // // and fill the corresponding field on the form.
        // for (let i = 0; i < place.address_components.length; i++) {
        //     const addressType = place.address_components[i].types[0];
        // //   if (componentForm[addressType]) {
        // //     const val = place.address_components[i][componentForm[addressType]];
        // //     document.getElementById(addressType).value = val;
        // //   }
        // }
    }

    function geolocate() {
        console.log('geolocated', navigator.geolocation)
        // if (navigator.geolocation) {
        //     navigator.geolocation.getCurrentPosition(function (position) {
        //         const geolocation = {
        //             lat: position.coords.latitude,
        //             lng: position.coords.longitude
        //         };
        //         const circle = new google.maps.Circle({
        //             center: geolocation,
        //             radius: position.coords.accuracy
        //         });
        //         autocomplete.setBounds(circle.getBounds());
        //     });
        // }
    }
</script> -->
<script>
    import { onMount } from 'svelte'
    import { placeChanged } from '../lib/Map/stores'
    import { member } from '../store/store.js'
    import { isEmptyObject } from 'is-what'

    let container
    let marker
    let geocoder
    let map
    let zoom = 8
    const controls = {
        mapTypeControl: false,
        streetViewControl: false,
        zoomControl: false,
        scaleControl: false,
        overviewMapControl: false,
        rotateControl: false,
        panControl: false,
        fullscreenControl: false
    }
    export let center = {}
    const google = window.google

    $: if (!isEmptyObject($placeChanged)) {
        $member.lat = $placeChanged.place.geometry.location.lat()
        $member.lng = $placeChanged.place.geometry.location.lng()
        $member.location = $placeChanged.place.formatted_address
        onInit()
    }

    async function onInit() {
        // const latLong =
        //     $member.lat !== null && $member.lng !== null
        //         ? { lat: $member.lat, lng: $member.lng }
        //         : { ...center }
        const latLong = { lat: $member.lat, lng: $member.lng }
        map = await new google.maps.Map(container, {
            zoom,
            center: latLong,
            ...controls,
        })

        marker = await new google.maps.Marker({
            position: latLong,
            draggable: true,
            animation: google.maps.Animation.DROP,
            map,
            title: 'Location',
        })

        await google.maps.event.addListener(marker, 'dragend', function () {
            geocodePosition(marker?.getPosition())
        })
    }

    function geocodePositionLanding() {
        if (!$member?.location) {
            const latlng = new google.maps.LatLng($member.lat, $member.lng);
            // This is making the Geocode request
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode({ 'latLng': latlng }, (results, status) => {
                if (status !== google.maps.GeocoderStatus.OK) {
                    alert(status);
                }
                // This is checking to see if the Geoeode Status is OK before proceeding
                if (status == google.maps.GeocoderStatus.OK) {
                    // console.log(results);
                    const address = (results[0].formatted_address);
                    // console.log(address)
                    member.set({ ...$member, location: address })
                }
            });
        }
        onInit()
    }

    onMount(() => {
        geocodePositionLanding()
        // onInit()
    })

    function geocodePosition(pos) {
        geocoder = new google.maps.Geocoder()
        geocoder.geocode(
            {
                latLng: pos,
            },
            (results, status) => {
                if (status == google.maps.GeocoderStatus.OK) {
                    $member.location = results[0].formatted_address
                    $member.lat = results[0].geometry.location.lat()
                    $member.lng = results[0].geometry.location.lng()
                } else {
                    console.error(
                        'Cannot determine address at this location.' + status
                    )
                }
            }
        )
    }
</script>

<div class="container">
    <p>
        Enter in the approximate address or move the map pin to the area where
        the incident happened.
    </p>
    <div class="search">
        <google-places-autocomplete placeholder="Enter your address" types={['geocode']} fields={['place_id', 'geometry'
            , 'name' , 'formatted_address' ]} {placeChanged} />
    </div>
    <div class="map" bind:this={container} />
</div>

<style>
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 352px;
        max-height: 350px;
        margin-bottom: 40px;
    }

    p {
        color: #5F6367;
    }


    .search {
        width: 100%;
        display: grid;
        margin-bottom: 10px;
    }

    .map {
        height: 260px;
        width: 350px;
    }

    @media (max-width: 500px) {
        .container {
            width: 300px;
        }

        .map {
            width: 300px;
        }
    }
</style>