<svelte:options tag="widget-app" />

<script>
    import { onMount } from 'svelte'
    import { isString, isBoolean } from 'is-what'
    import {
        step,
        type,
        apiData,
        // agencyData,
        agreementCheckbox,
        categories,
        member,
    } from './store/store.js'

    const RECAPTCHA_SRC = `https://www.google.com/recaptcha/api.js?render=${process.env.RECAPTCHA_SITE_KEY}`
    const MAP_SRC = `https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_MAP_API_KEY}&libraries=places`

    const urlParams = new URLSearchParams(location.search)
    export let agency_id,
        x_axis = 'right',
        y_axis = 'bottom'
    let open = urlParams.has('widget')
    let complete = false
    let fail = null

    if (open) {
        let setType = urlParams.get('widget')
        if (setType === 'praise' || setType === 'grievance') type.click(setType)
    }

    function handleMain(showMessage) {
        if (isBoolean(showMessage)) {
            complete = showMessage
            fail = null
        }
        if (isString(showMessage)) {
            fail = showMessage
        }
        open = !open
        step.reset()
        agreementCheckbox.reset()
    }

    const geo = async () =>
        await navigator.geolocation.getCurrentPosition(position => {
            member.set({
                agency: agency_id,
                lat: position.coords.latitude,
                lng: position.coords.longitude,
            })
        })

    onMount(async () => {
        const permissionStatus = await navigator?.permissions?.query({name: 'geolocation'})
        const hasPermission = permissionStatus?.state 

        if (hasPermission === 'granted') {
            await geo()
        } else {
            console.log('GPS access: ', hasPermission)
            member.set({
                agency: agency_id,
                lat: 33.7540433,
                lng: -117.8615241,
            })
        }

        // fetch(
        //     `${process.env.API_URL}/agency/${agency_id}`
        // )
        //     .then(response => response.json())
        //     .then(({ data }) => {
        //         apiData.set(data);
        //     })
        //     .catch(error => {
        //         console.log(error)
        //         return {}
        //     })

        const res = await Promise.all([
            fetch(`${process.env.API_URL}/agency/${agency_id}`),
            fetch(`${process.env.API_URL}/voice/category`),
        ])
        const data = await Promise.all(res.map(r => r.json()))
        const response = data.flat()
        apiData.set(response[0]?.data)

        const category = response[1]?.data.map(items => {
            return {
                ...items,
                selected: false,
            }
        })
        categories.set(category)
    })
</script>

<svelte:head>
    <script async defer src={MAP_SRC}></script>
    <script async defer src={RECAPTCHA_SRC}></script>
</svelte:head>

<div class={`card-widget card-widget-${x_axis} card-widget-${y_axis}`}>
    {#if open}
        <div class="open-container">
            <div class="header-btn">
                <button
                    style="margin-right: 20px;"
                    class="button-widget-black"
                    on:click={() => handleMain(false)}
                >
                    <div>
                        <span>Close</span>&nbsp;
                        <svg
                            viewBox="0 0 12 12"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="12px"
                        >
                            <line
                                x1="1"
                                y1="11"
                                x2="11"
                                y2="1"
                                stroke-width="2"
                                stroke="currentColor"
                                fill="currentColor"
                            />
                            <line
                                x1="1"
                                y1="1"
                                x2="11"
                                y2="11"
                                stroke-width="2"
                                stroke="currentColor"
                                fill="currentColor"
                            />
                        </svg>
                    </div>
                </button>
                {#if $step !== 0}
                    <button
                        style="margin-left: 20px;"
                        class="button-widget-black"
                        on:click={() => step.click($step - 1)}
                    >
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                clip-rule="evenodd"
                                fill-rule="evenodd"
                                stroke-linejoin="round"
                                stroke-miterlimit="2"
                                viewBox="0 4 24 16"
                                ><path
                                    d="m9.474 5.209s-4.501 4.505-6.254 6.259c-.147.146-.22.338-.22.53s.073.384.22.53c1.752 1.754 6.252 6.257 6.252 6.257.145.145.336.217.527.217.191-.001.383-.074.53-.221.293-.293.294-.766.004-1.057l-4.976-4.976h14.692c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-14.692l4.978-4.979c.289-.289.287-.761-.006-1.054-.147-.147-.339-.221-.53-.221-.191-.001-.38.071-.525.215z"
                                    fill-rule="nonzero"
                                    stroke="currentColor"
                                    fill="currentColor"
                                /></svg
                            >
                            &nbsp;<span>Go Back</span>
                        </div>
                    </button>
                {/if}
            </div>
            <div class="header">
                <!-- <verum-logo width={30} /> -->
                <!-- <img width="70" src={$agencyData.logo} /> -->
                <h2>Your Voice</h2>
            </div>
            <!-- <div class="title">
            <img width="60" src={$agencyData.logo} />
            <h2>{$apiData.name}</h2>
        </div> -->
            <verum-stepper close={handleMain} />
        </div>
    {:else}
        <button class="button-widget open-btn" on:click={handleMain}>
            <div class="button-widget-logo">
                <verum-logo width={20} />
                <div>Your Voice</div>
            </div>
        </button>
        {#if complete && !fail}
            <p class="green">Voice Submitted!</p>
        {:else if !complete && fail}
            <p class="red">{fail}</p>
        {/if}
    {/if}
</div>

<style>
    * {
        font-family: 'Poppins', sans-serif;
    }

    .card-widget {
        position: fixed;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: white;
        border-radius: 25px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        transition: 0.3s;
        margin: 6px;
        z-index: 9999;
        /* max-height: 100vh; */
        /* overflow-y: auto; */
    }

    .card-widget-top {
        top: 10px;
    }

    .card-widget-bottom {
        bottom: 10px;
    }

    .card-widget-right {
        right: 10px;
    }

    .card-widget-left {
        left: 10px;
    }

    /* On mouse-over, add a deeper shadow */
    .card-widget:hover {
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    }

    .header-btn {
        display: flex;
        flex-flow: row-reverse;
        justify-content: space-between;
    }

    .button-widget-black {
        padding: 4px;
        border: none;
        background-color: white;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 12px;
        border-radius: 4px;
        transition-duration: 0.4s;
        color: #5f6367;
        font-weight: bold;
    }

    .button-widget-black:hover {
        color: #1670c9;
        cursor: pointer;
    }

    .button-widget-black > div {
        display: flex;
        height: 12px;
        text-wrap: nowrap;
    }

    .button-widget {
        background-color: white;
        /* Green */
        border: solid 1px #1670c9;
        color: #1670c9;
        padding: 8px 12px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        border-radius: 25px;
    }

    .button-widget {
        transition-duration: 0.4s;
    }

    .button-widget:hover {
        background-color: #d5dfea;
        color: #1670c9;
        border: solid 1px #1670c9;
        cursor: pointer;
    }

    .button-widget-logo {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }

    .button-widget-logo > * {
        margin-right: 2px;
        margin-left: 2px;
    }

    .open-container {
        display: flex;
        flex-direction: column;
        width: 450px;
        padding-top: 20px;
        height: 674px;
    }

    .header {
        display: flex;
        justify-content: space-between;
        font-size: 36px;
        font-family: Poppins;
        margin: 24px 0;
    }

    .header > h2 {
        width: 100%;
        display: flex;
        justify-content: center;
        font-weight: 300;
        margin: 0;
    }

    .title {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }

    .title > * {
        margin-left: 10px;
        margin-right: 10px;
    }

    .button-widget-logo {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }

    .button-widget-logo > svg {
        margin-right: 10px;
    }

    .open-btn {
        padding: 14px 20px;
    }

    .green {
        color: #427873;
    }

    .red {
        color: red;
    }

    @media (max-width: 500px) {
        .card-widget-left {
            left: 5px;
        }

        .card-widget-right {
            right: 5px;
        }

        .open-container {
            width: 350px;
            height: 590px;
        }

        .header {
            font-size: 17px;
            margin: 0;
        }
    }
</style>
