<svelte:options tag="google-places-autocomplete" />

<script>
    import { onMount } from 'svelte'
    import {
        placeChanged,
        ready,
        blur as mapBlur,
        clear as mapClear,
    } from './stores.js'

    export let id = `gm-autocomplete-${Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '')
        .substr(0, 5)}`
    export let ariaLabel = 'location'
    export let placeholder = 'Location'
    export let styleClass = ''
    export let value = null
    export let viewValue = null
    export let viewLabel = 'formatted_address'
    export let fields = ['geometry', viewLabel]
    export let types = ['(regions)']
    export let options = {}

    let search
    let autocomplete
    let currentPlace
    let disabled = true

    export function clear() {
        value = null
        viewValue = null
        currentPlace = null

        mapClear.set(true)
    }

    function dropdownVisible() {
        return (
            document.querySelectorAll('.pac-container')[0].offsetParent !== null
        )
    }

    function autocompleteKeydown(e) {
        if (e.keyCode === 13 && dropdownVisible()) {
            e.preventDefault()
        }
    }

    function blur() {
        mapBlur.set(true)
        if (viewValue !== (currentPlace && currentPlace[viewLabel])) {
            clear()
        }
    }

    function initialise() {
        const google = window.google
        autocomplete = new google.maps.places.Autocomplete(
            search,
            Object.assign(
                {
                    fields,
                    types,
                },
                options
            )
        )

        disabled = false

        autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace()

            if (place.geometry) {
                viewValue = place[viewLabel]
                value = viewValue
                currentPlace = place
                placeChanged.set({ place, selectedPrediction: search.value })
            }
        })

        ready.set(true)
    }

    onMount(() => {
        initialise()
    })
</script>

<!-- <google-sdk {initialise} /> -->
<input
    {id}
    aria-label={ariaLabel}
    style="z-index: 9999;"
    class={styleClass}
    {placeholder}
    bind:this={search}
    type="search"
    {disabled}
    bind:value={viewValue}
    on:blur={blur}
    on:keydown={autocompleteKeydown}
/>

<style>
    input {
        padding: 10px 0 10px 10px;
        border: 1px solid #DFE2E5;
        width: 100%;
        border-radius: 4px;
    }

    input[type='text' i] {
        font-family: 'Poppins', sans-serif;
    }
</style>
