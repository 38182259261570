<svelte:options tag="verum-step-type" />

<script>
// @ts-nocheck
    import { type, categories, agencyData } from '../store/store.js'

    export let options = [
        {
            value: 'praise',
            label: `${$agencyData?.configs?.type?.praise || 'compliment'}`,
            subLabel: 'Commodation for an individual officer or department',
        },
        {
            value: 'grievance',
            label: `${$agencyData?.configs?.type?.grievance || 'compliant'}`,
            subLabel: 'Grievance about an individual officer or department',
        },
        // {
        //     value: 'other',
        //     label: 'Other',
        //     subLabel: 'Any other comments'
        // }
    ]

    const uniqueID = Math.floor(Math.random() * 100)

    const slugify = (str = '') =>
        str.toLowerCase().replace(/ /g, '-').replace(/\./g, '')

    function categoryHelper(value) {
        return $categories.map(item => {
            if (value !== 'other' && value !== item.type) {
                return {
                    ...item,
                    selected: false,
                }
            }
            return item
        })
    }

    function handleClick(e) {
        type.click(e.target.value)

        $categories = categoryHelper(e.target.value)
    }
</script>

<div class="container">
    <p>What type of voice is this?</p>
    <div>
        {#each options as { value, label, subLabel }}
            <input
                class="sr-only"
                type="radio"
                id={slugify(label)}
                on:click={e => handleClick(e)}
                name={value}
                {value}
                checked={$type === value}
            />
            <label class={$type === value ? 'blue' : 'black'} for={slugify(label)}>
                {#if value === 'grievance'}<verum-thumbsup
                        {label}
                        size={18}
                        down={true}
                        >{label}
                    </verum-thumbsup>{:else if value === 'praise'}<verum-thumbsup
                        {label}
                        size={18}
                        >{label}
                    </verum-thumbsup>{:else}{label}{/if}<span
                    class={$type === value ? 'blue' : 'grey'}>{subLabel}</span
                >
            </label>
        {/each}
    </div>
</div>

<style>
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 352px;
        max-height: 350px;
        margin-bottom: 40px;
    }

    p {
        color: #5F6367;
    }

    input[type='radio'] {
        appearance: none;
    }

    .blue {
        color: #1670c9;
    }

    .grey {
        color: #5F6367;
    }

    input[type='radio'] + label {
        cursor: pointer;
        position: relative;
        padding-left: 30px;
        line-height: 20px;
    }

    input[type='radio'] + label::before {
        content: '';
        display: inline-block;
        width: 20px;
        aspect-ratio: 1;
        border: 1px solid black;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        opacity: 1;
        transition: all 0.3s;
    }

    input[type='radio']:checked + label::before {
        border: 1px solid #1670c9;
    }

    input[type='radio'] + label::after {
        content: '';
        display: inline-block;
        width: 10px;
        aspect-ratio: 1;
        border: 1px solid #1670c9;
        background: #1670c9;
        border-radius: 50%;
        position: absolute;
        left: 5px;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0;
        transition: all 0.3s;
    }

    input[type='radio']:checked + label::after {
        opacity: 1;
    }

    label {
        display: flex;
        flex-direction: column;
        width: 220px;
        margin-bottom: 6px;
    }
</style>
