<svelte:options tag="verum-logo"/>

<script>
    export let width = 20
</script>

<svg width={width} viewBox="0 0 34 31" fill="none" xmlns="http://www.w3.org/2000/svg"
    ><path
        d="M10.29.035h-10l10.92 30.001h10l-5-13.738L10.29.035z"
        fill="url(#prefix__paint0_linear_554_1060)"
    /><path
        d="M32.128.035h-10l-4.669 12.83 5 13.736 5-13.737L32.13.035z"
        fill="url(#prefix__paint1_linear_554_1060)"
    /><path
        d="M28.71 16.298l-5 13.738h10l-5-13.738z"
        fill="url(#prefix__paint2_linear_554_1060)"
    /><defs
        ><linearGradient
            id="prefix__paint0_linear_554_1060"
            x1="10.75"
            y1=".035"
            x2="10.75"
            y2="30.036"
            gradientUnits="userSpaceOnUse"
            ><stop stop-color="#D9567D" /><stop
                offset=".2"
                stop-color="#C45F90"
            /><stop offset=".4" stop-color="#AD67A3" /><stop
                offset=".6"
                stop-color="#906DB7"
            /><stop offset=".8" stop-color="#6873CA" /><stop
                offset="1"
                stop-color="#0078DE"
            /></linearGradient
        ><linearGradient
            id="prefix__paint1_linear_554_1060"
            x1="24.794"
            y1=".035"
            x2="24.794"
            y2="26.601"
            gradientUnits="userSpaceOnUse"
            ><stop stop-color="#D9567D" /><stop
                offset=".2"
                stop-color="#C45F90"
            /><stop offset=".4" stop-color="#AD67A3" /><stop
                offset=".6"
                stop-color="#906DB7"
            /><stop offset=".8" stop-color="#6873CA" /><stop
                offset="1"
                stop-color="#0078DE"
            /></linearGradient
        ><linearGradient
            id="prefix__paint2_linear_554_1060"
            x1="28.709"
            y1="16.298"
            x2="28.709"
            y2="30.036"
            gradientUnits="userSpaceOnUse"
            ><stop stop-color="#D9567D" /><stop
                offset=".2"
                stop-color="#C45F90"
            /><stop offset=".4" stop-color="#AD67A3" /><stop
                offset=".6"
                stop-color="#906DB7"
            /><stop offset=".8" stop-color="#6873CA" /><stop
                offset="1"
                stop-color="#0078DE"
            /></linearGradient
        ></defs
    ></svg
>
