<svelte:options tag="verum-step-comment" />

<script>
    import { member, fileUploads } from "../store/store.js";

    let fileinput;
    let uploads = $fileUploads

    const onFileSelected = (e) => {
        let image = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = e => {
            uploads = [...uploads, {
                name: image.name,
                img: e.target.result
            }]
            fileUploads.set(uploads)
        };
    }

    function handleDelete(i) {
        if(i === 0) {
            const tmp = uploads.shift()
            uploads = [...uploads]
            fileUploads.set(uploads)
        } else {
            const tmp = uploads.splice(0, i)
            uploads = tmp 
            fileUploads.set(uploads)
        }
    }
</script>

<div class="container">
    <div class="textarea">
        <p>Describe the incident and any additional comments you may have. You may also attach any relevant photos.</p>
        <textarea rows="10" cols="50" placeholder="Enter Comments here" type="textarea" name="comments"
            bind:value={$member.comments}></textarea>
    </div>
    <!-- <input type="file" class="custom-file-input"
        accept=".jpg, .jpeg, .png, .webp, .mp4, .mov, .wmv, .flv, avi, .webm, .mkv" on:change={(e)=>onFileSelected(e)}
    bind:this={fileinput}>
    {#if uploads.length === 0}
    <span class="text">No File Chosen</span>
    {/if}

    {#each uploads as upload, i}
    <div class="images">
        <img class="avatar" width="50" height="50" src={upload.img} alt="video or image upload" />
        {upload.name}
        <button on:click={() => handleDelete(i)}>
            <svg viewBox="0 0 320 512" width="30px" height="30px" fill="#848A8E" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z" />
            </svg>
        </button>
    </div>
    {/each} -->
</div>

<style>
    .container {
        max-width: 352px;
        max-height: 350px;
        margin-bottom: 40px;
    }

    p {
        color: #5F6367;
    }

    .textarea {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    textarea {
        font-family: 'Poppins', sans-serif;
        margin: 10px 10px 18px 10px;
        border: 1px solid #DFE2E5;
        width: 100%;
        border-radius: 4px;
    }

    /*custom inpu file*/
    .custom-file-input {
        color: transparent;
        margin-bottom: 18px;

    }

    .custom-file-input::-webkit-file-upload-button {
        visibility: hidden;
    }

    .custom-file-input::before {
        content: "▲ UPLOAD FILE(S)";
        color: #5F6367;
        display: inline-block;
        border: 1px solid #DFE2E5;
        background: #fff;
        padding: 10px 22px;
        outline: none;
        white-space: nowrap;
        /* -webkit-user-select: none; */
        cursor: pointer;
        font-weight: 600;
        border-radius: 2px;
        outline: none;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
    }

    .custom-file-input:focus {
        outline: none !important;

    }

    .custom-file-input:active::before {
        transform: scale(.9) translate(0px, 2px);
        box-shadow: inset 4px 4px 5px 0px rgba(0, 0, 0, 0.20);

    }

    .images {
        margin-bottom: 6px;
        align-items: center;
        display: flex;
    }

    .text {
        color: #5F6367;
        font-weight: 300;
    }

    button {
        border: none;
        background-color: #fff;
        color: #ffffff;
        padding: 5px 10px;
        cursor: pointer;
    }

    button:hover>* {
        fill: #B0B8BD;
    }

    img {
        border-radius: 5px;
        margin-right: 6px;
    }

    @media (max-width: 500px) {
        .container {
            width: 300px;
        }
    }
</style>