<svelte:options tag="verum-stepper" />

<script>
    import { onMount } from 'svelte'
    import {
        step,
        type,
        categories,
        member,
        agreementCheckbox,
    } from '../store/store.js'

    const version = process.env.__VERSION__

    let error = ''
    // TODO: might not need this.
    let requests = 0
    let contact = null
    let invalid = false
    let empty = false
    export let close = () => {}

    // let index = 0
    // function step(num) {
    //     index = index + num
    // }

    export let center = { lat: -34.397, lng: 150.644 }
    function getLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition)
        }
    }

    function showPosition(position) {
        center = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
        }
    }

    function handleSubmit() {
        error = ''
        if (contact === null) {
            empty = true
        } else if (contact && !invalid) {
            const category = $categories.reduce((acc, item) => {
                if (item.selected) {
                    acc.push(item.id)
                }
                return acc
            }, [])

            const body = {
                contact,
                agency: Number($member.agency),
                type: $type,
                happenedAt: $member.time,
                categories: category,
                badge: $member.badge,
                referenceType: $member.referenceType,
                reference: $member.citation,
                referenceName: $member.name,
                referenceDetail: $member.description,
                referenceLocation: $member.location,
                LAT: $member.lat,
                LON: $member.lng,
                comment: $member.comments,
            }

            doRecaptcha(body)
        }
    }

    async function doRecaptcha(body) {
        grecaptcha.ready(() => {
            grecaptcha
                .execute(process.env.RECAPTCHA_SITE_KEY, { action: 'submit' })
                .then(token => {
                    fetch(`${process.env.API_URL}/voice`, {
                        // fetch('https://jsonplaceholder.typicode.com/posts/1', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            ...body,
                            recaptcha: token,
                        }),
                    })
                        .then(res => {
                            if (res.ok) {
                                close(true)
                                agreementCheckbox.reset()
                            } else {
                                console.error(res)
                                close('Error: failed to Submit')
                            }
                            requests += 1
                        })
                        .catch(err => {
                            console.error(err)
                            error = 'Recaptcha error. Please reload the page'
                        })
                    // reset recaptcha for future requests
                    // resetCaptcha();
                    // grecaptcha.reset()
                })
        })
    }

    function handleValidation() {
        if (contact) {
            empty = false
            invalid = !(
                /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(
                    contact
                ) ||
                /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(contact)
            )
        } else if (!contact && contact !== null) {
            empty = true
            invalid = false
        } else {
            empty = false
            invalid = false
        }
    }

    onMount(() => {
        getLocation()
    })
</script>

<!-- {requests} -->
<!-- {#if $step !== 6} -->
<div class="container">
    {#if $step !== 0}
        <button on:click={() => step.click($step - 1)}
            ><svg
                width="15"
                height="24"
                viewBox="0 0 9 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M7.41 10.58L2.83 6L7.41 1.41L6 0L0 6L6 12L7.41 10.58Z"
                    fill="#5F6367"
                />
            </svg></button
        >
    {:else}
        <svg
            width="15"
            height="24"
            viewBox="0 0 9 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.41 10.58L2.83 6L7.41 1.41L6 0L0 6L6 12L7.41 10.58Z"
                fill="#5F6367"
            />
        </svg>
    {/if}
    <span class={`dot ${$step === 0 ? 'active' : 'in-active'}`} />
    <span class={`dot ${$step === 1 ? 'active' : 'in-active'}`} />
    <span class={`dot ${$step === 2 ? 'active' : 'in-active'}`} />
    <span class={`dot ${$step === 3 ? 'active' : 'in-active'}`} />
    <span class={`dot ${$step === 4 ? 'active' : 'in-active'}`} />
    <span class={`dot ${$step === 5 ? 'active' : 'in-active'}`} />
    <span class={`dot ${$step === 6 ? 'active' : 'in-active'}`} />
    {#if $step !== 6}
        <button on:click={() => step.click($step + 1)}
            ><svg
                width="15"
                height="24"
                viewBox="0 0 9 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M0.589996 10.58L5.17 6L0.589996 1.41L2 0L8 6L2 12L0.589996 10.58Z"
                    fill="#5F6367"
                />
            </svg></button
        >
    {:else}
        <svg
            width="15"
            height="24"
            viewBox="0 0 9 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.589996 10.58L5.17 6L0.589996 1.41L2 0L8 6L2 12L0.589996 10.58Z"
                fill="#5F6367"
            />
        </svg>
    {/if}
</div>
<!-- {/if} -->

<div class="center">
    {#if $step === 0}
        <verum-step-type />
    {:else if $step === 1}
        <verum-step-category />
    {:else if $step === 2}
        <verum-step-location {center} />
    {:else if $step === 3}
        <verum-step-time />
    {:else if $step === 4}
        <verum-step-member />
    {:else if $step === 5}
        <verum-step-comment />
    {:else}
        <div class="summary">
            <input
                type="text"
                placeholder="Phone or Email"
                name="contact"
                bind:value={contact}
                on:input={handleValidation}
            />
            {#if invalid && contact !== null}
                <div class="error">
                    <small>Use valid email or phone</small>
                </div>
            {:else if empty}
                <div class="error">
                    <small>Email or phone number is required</small>
                </div>
            {/if}
            <verum-summary />
        </div>
    {/if}
    <div class="btn">
        {#if $step === 0}
            <button class="primary" on:click={() => step.click($step + 1)}
                >Next</button
            >
            <!-- <div style="height: 18px"></div> -->
            <!-- <span class="version">{version}</span> -->
        {:else if $step === 6}
            {#if error}
                <div class="error">
                    <small>{error}</small>
                </div>
            {/if}
            {#if $agreementCheckbox}
                <form class="form" on:submit|preventDefault={handleSubmit}>
                    <button type="submit" class="primary">Submit Voice</button>
                </form>
            {:else}
                <form class="form" on:submit|preventDefault={() => {}}>
                    <button type="submit" class="disabled">Submit Voice</button>
                </form>
            {/if}
        {:else}
            <button class="primary" on:click={() => step.click($step + 1)}
                >{$step === 5 ? 'Review' : 'Next'}</button
            >
        {/if}
        <!-- {#if $step !== 0}
            <button class="secondary" on:click={() => step.click($step - 1)}>Go Back</button>
        {/if} -->
        <span class="version">{version}</span>
    </div>
</div>

<style>
    .center {
        display: flex;
        justify-content: center;
        overflow-y: auto;
    }
    button {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
    }

    .btn {
        display: flex;
        flex-direction: column;
        bottom: -1px;
        position: absolute;
        width: -webkit-fill-available;
    }

    .btn > button:first-child {
        margin-bottom: 10px;
    }

    .form {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
    }

    .primary {
        background-color: #1670c9;
        color: #fff;
        padding: 16px;
        border-radius: 48px;
        width: 352px;
        align-self: center;
        text-transform: uppercase;
        font-weight: bold;
    }

    .primary:hover {
        background-color: #4c8bd6;
    }

    .primary:active {
        background-color: #9fb9da;
    }

    .secondary {
        color: #1670c9;
    }

    .secondary:hover {
        color: #4c8bd6;
    }

    .secondary:active {
        color: #275284;
    }

    .disabled {
        background-color: #bbb;
        color: #fff;
        padding: 16px;
        border-radius: 48px;
        width: 352px;
        align-self: center;
        text-transform: uppercase;
        font-weight: bold;
        cursor: not-allowed;
    }

    .container {
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 20px;
    }

    .dot {
        height: 12px;
        width: 12px;
        background-color: #bbb;
        border-radius: 50%;
        display: inline-block;
        align-self: center;
        margin-bottom: 2px;
    }

    .in-active {
        background-color: #bbb;
    }

    .active {
        background-color: #1670c9;
    }

    .error {
        color: red;
        font-weight: 600;
        align-self: center;
        margin-bottom: 10px;
    }

    .summary {
        display: flex;
        flex-flow: column;
    }

    input {
        /* width: 100%; */
        display: flex;
        padding: 10px 20px 10px 20px;
        border: 1px solid #dfe2e5;
        border-radius: 4px;
    }

    input[type='datetime-local' i] {
        font-family: 'Poppins', sans-serif;
    }

    .version {
        display: flex;
        align-self: center;
        color: #5f6367;
        font-size: 12px;
        margin-bottom: 12px;
    }

    @media (max-width: 500px) {
        .primary, .disabled {
            width: 260px;
        }
    }
</style>
